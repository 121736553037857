<template>
  <div class="container">
    <div v-if="doesOrderExists">
      <div class="columns">
        <div class="column is-1 is-offset-2">
          <b-button
            type="is-primary"
            icon-left="arrow-left"
            expanded
            @click="goBack"
            rounded
            >Powrót</b-button
          >
        </div>
        <div class="column box">
          <div class="columns p-2">
            <div class="column">
              Numer zamówienia: <strong>{{ order.uid }}</strong>
            </div>
          </div>
          <div class="block"></div>
          <hr />
          <div class="columns p-2">
            <div class="column is-half">
              <div>Dane osobowe</div>
              <div>
                Imię i nazwisko:
                <strong
                  >{{ order.user.details.first_name }}
                  {{ order.user.details.last_name }}</strong
                >
              </div>
              <div>
                Adres:
                <strong>
                  {{ order.user.address[0].local_nb }}
                  {{ order.user.address[0].street }},
                  {{ order.user.address[0].city }}
                  {{ order.user.address[0].postcode }}
                </strong>
              </div>
              <div>
                Telefon:
                <strong>
                  {{ order.user.details.phone }}
                </strong>
              </div>
              <div>
                Email:
                <strong>
                  {{ order.user.email }}
                </strong>
              </div>
            </div>
          </div>
          <div class="block"></div>
          <hr />
          <div class="columns p-2">
            <div class="column is-half">
              <div>
                Data utworzenia:
                <strong>
                  {{ dateToStr(new Date(order.history[0].created_at)) }}
                </strong>
              </div>
              <div>
                <!-- Nie wyświetlam liczby sekund oraz dwukropka miedzy minutami a sekundami -->
                Termin realizacji:
                <strong>
                  {{
                    order.booking_time.substring(
                      0,
                      order.booking_time.length - 3
                    )
                  }}
                </strong>
              </div>
            </div>
            <div class="column is-half">
              Status: <strong>{{ order.status.name || "-" }}</strong> <br />
              Płatność:
              <strong>
                {{
                  order.payment && order.payment[0] && order.payment[0].is_paid
                    ? "OPŁACONO"
                    : "NIEOPŁACONO"
                }}
              </strong>
              <br />
              Rodzaj płatności:
              <strong> ({{ order.payment_type.name || "-" }}) </strong>
            </div>
          </div>
          <div class="block"></div>
          <hr />
          <div class="columns p-2">
            <div class="column is-half">
              Samochód:
              <div>
                Marka:
                <strong>{{
                  contextCar.car_details.serie.model.brand.name || "-"
                }}</strong>
              </div>
              <div>
                Model:
                <strong>
                  {{ contextCar.car_details.serie.model.name || "-" }}</strong
                >
              </div>
              <div>
                Silnik:
                <strong>
                  {{ contextCar.car_details.engine.name || "-" }}</strong
                >
              </div>
            </div>
            <div class="column is-half">
              <div>
                Wykonawca:
                <strong>{{ order.company.name || "-" }}</strong>
              </div>
              <div>
                Adres
                <strong>
                  {{ order.company.address[0].local_nb }}
                  {{ order.company.address[0].street }},
                  {{ order.company.address[0].city }}
                  {{ order.company.address[0].postcode }}
                </strong>
              </div>
              <div>
                Telefon
                <strong>{{ order.company.details.phone }}</strong>
              </div>
              <div>
                Email
                <strong>{{ order.company.email }}</strong>
              </div>
            </div>
          </div>
          <div class="block"></div>
          <hr />
          <div class="columns is-multiline p-2">
            <div class="column is-full">
              <div class="level">
                <div class="level-left">
                  <div class="level-item">Wartość zamówienia:</div>
                </div>
                <div class="level-right">
                  <div class="level-item">
                    <b-tag type="is-primary" size="is-medium"
                      >{{ order.summary_value }} zł</b-tag
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="column is-full">
              <b-table :data="order.services" striped detailed>
                <b-table-column
                  field="type"
                  label="Nazwa usługi"
                  v-slot="props"
                >
                  {{ props.row.type.name || "-" }}
                </b-table-column>
                <b-table-column field="value" label="Cena" v-slot="props">
                  {{ props.row.value }} zł
                </b-table-column>

                <template #detail="props">
                  <div>
                    <div>Opis usługi: {{ props.row.type.description }}</div>
                    <div>Wybrany wariant: {{ props.row.name || "-" }}</div>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
          <div class="col ml-3 mt-6">
            <!--v-if="doesOrderHistoryExists"-->
            <div class="">Historia zamówienia</div>
            <div class="block"></div>
            <hr />
            <div class="column is-full">
              <b-table :data="order.history" striped>
                <b-table-column field="status" label="Status" v-slot="props">
                  {{
                    getOrderStatusById(props.row.order_status_id).name || "-"
                  }}
                </b-table-column>
                <b-table-column
                  field="comment"
                  label="Komentarz"
                  v-slot="props"
                >
                  {{ props.row.comment || "-" }}
                </b-table-column>
                <b-table-column
                  field="created_at"
                  label="Data zmiany"
                  v-slot="props"
                >
                  {{
                    props.row.created_at.split("T")[0] +
                    " " +
                    props.row.created_at.split("T")[1].split(".")[0]
                  }}
                </b-table-column>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  created() {
    this.$store
      .dispatch("clientOrders/getOrderById", this.$route.params.id)
      .then((response) => {
        if (response.status !== 200) {
          //console.log("Błąd pobierania informacji o zamówieniu");
          return;
        }
      });
  },

  data() {
    return {};
  },

  methods: {
    dateToStr(d) {
      let hoursStr = d.getHours();
      if (hoursStr < 10) {
        hoursStr = `0${hoursStr}`;
      }
      let minutesStr = d.getMinutes();
      if (minutesStr < 10) {
        minutesStr = `0${minutesStr}`;
      }
      let dateStr = `${d.getFullYear()}-${
        d.getMonth() + 1
      }-${d.getDate()}, ${hoursStr}:${minutesStr}`;
      return dateStr;
    },

    goBack() {
      this.$router.push({name: 'Orders'});
    },
  },

  computed: {
    ...mapGetters("companyOrders", ["getOrderStatusById"]),
    order() {
      return this.$store.getters["clientOrders/getContextOrder"];
      /* return this.$store.getters["clientOrders/getOrderById"](
        this.$route.params.id
      ); */
    },

    doesOrderExists() {
      return this.order !== undefined;
    },

    contextCar() {
      if (!this.order.my_car_id) {
        return;
      }

      const car = this.$store.getters["clientCars/getCarById"](
        this.order.my_car_id
      );

      // Jeżeli nie ma auta o id "my_car_id", to wywolaj akcje getCars w celu pobrania aut
      if (car) {
        return car;
      } else {
        this.$store.dispatch("clientCars/getCars");
      }

      return car;
    },
  },
};
</script>
<style lang="scss">
.left-border-primary {
  border-left: 0.1rem solid $primary;
}

.right-border-primary {
  border-right: 0.1rem solid $primary;
}
</style>